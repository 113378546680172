import React from 'react'
import Layout from '../components/layout'

const Agb = () => {
	return (
		<Layout title="Agb">
			<div className="container-fluid pl-4">
				<div className="my-row mt-5">
					<h2 className="font-weight-lighter">Allgemeine Geschäftsbedingungen der betterHR GmbH</h2>
				</div>
				<div className="my-row mt-4">
					<h4>
          1. Geltungsbereich, Änderungen
					</h4>
				</div>
				<div className="my-row">
					<p>
          1.1 Die nachfolgenden allgemeinen Geschäftsbedingungen (nachfolgend „AGB“) regeln die Nutzung der von der betterHR GmbH, Helmholtzstraße 2-9, DE-10587 Berlin (nachfolgend „betterHR“) auf der Internetwebseite <b>www.betterhr.de</b> bereit gestellten Informations- und sonstigen Dienste (nachfolgend insgesamt „betterHR-Dienste“). Nutzer der betterHR-Dienste werden nachfolgend als „Kunden“ bzw. „Kunde“ bezeichnet.
					</p>
					<p className="mt-2">
          1.2 betterHR erbringt die betterHR-Dienste gegenüber ihren Kunden ausschließlich auf Basis dieser AGB. Allgemeine Geschäftsbestimmungen des Kunden werden ausdrücklich nicht Bestandteil, es sei denn, betterHR stimmt diesen ausdrücklich schriftlich zu. Mit der Registrierung und anschließenden Annahme durch betterHR schaltet betterHR den Kunden für weitere betterHR – Dienste frei.
					</p>
					<p className="mt-2">
          1.3 betterHR behält sich vor, diese AGB jederzeit mit Wirkung auch innerhalb der bestehenden Vertragsverhältnisse zu ändern. Über derartige Änderungen wird betterHR den Kunden mindestens 30 Tage vor dem geplanten Inkrafttreten in Kenntnis setzen. Sofern der Kunde nicht innerhalb von 30 Tagen ab Zugang der Mitteilung widerspricht und die Inanspruchnahme der betterHR-Dienste auch nach Ablauf der Widerspruchsfrist fortsetzt, so gelten die Änderungen ab Fristablauf zwischen dem Kunden und betterHR als rechtswirksam vereinbart. Im Falle des Widerspruchs wird der Vertrag zu den bisherigen Bedingungen fortgesetzt, betterHR steht in diesem Fall jedoch ein Sonderkündigungsrecht zu. In der Änderungsmitteilung wird betterHR den Kunden auf sein Widerspruchsrecht und die Folgen hinweisen.
					</p>
				</div>
				<hr/>
				<div className="my-row mt-4">
					<h4>
          2. Registrierung, Pflichten des Kunde
					</h4>
				</div>
				<div className="my-row">
					<p>
          2.1 Die vollständige Nutzung der betterHR-Dienste setzt die Registrierung des Kunden voraus. Die Registrierung erfolgt durch die Eröffnung eines Kundenkontos unter Zustimmung zu diesen AGB und der Bestätigung der Kenntnisnahme der Datenschutzerklärung.
					</p>
					<p className="mt-2">
          2.2 Das Angebot von betterHR richtet sich ausschließlich an Unternehmer im Sinne des § 14 BGB. Die Anmeldung ist juristischen Personen, Personengesellschaften und unbeschränkt geschäftsfähigen natürlichen Personen in Ihrer Eigenschaft als Unternehmer erlaubt. Die Anmeldung von einer juristischen Person oder Personengesellschaft darf nur von einer vertretungsberechtigten natürlichen Einzelperson vorgenommen werden, die namentlich benannt werden muss.
					</p>
					<p className="mt-2">
          2.3 Der Kunde wird im Rahmen der Registrierung gebeten, eine E-Mail-Adresse und ein Passwort anzugeben. Der Kunde ist verpflichtet, das für die Anmeldung erstellte Passwort geheim zu halten und den Zugang zu seinem Kundenkonto sorgfältig zu sichern. Der Kunde ist verpflichtet, betterHR umgehend zu informieren, sofern es Anhaltspunkte dafür gibt, dass sein Kundenkonto von Dritten missbraucht wurde. Der Kunde haftet betterHR nach den gesetzlichen Bestimmungen für jedwede Aktivitäten, welche unter seine Kundenkonto ausgeführt werden.
					</p>
					<p className="mt-2">
          2.4 Nach Angabe der im Rahmen der Registrierung abgefragten Daten werden diese durch betterHR im Hinblick auf Ihre Vollständigkeit und Plausibilität geprüft. Sind die Angaben aus Sicht von betterHR korrekt und bestehen im Übrigen keine sonstigen Bedenken, schaltet betterHR den Zugang des Kunden frei und informiert den Kunden hierüber per E-Mail. Eine vollständige Freischaltung des Kundenkontos erfordert ein Anklicken des in der E-Mail enthaltenen Links durch den Kunden („Double Opt-in“).
					</p>
					<p className="mt-2">
          2.5 Der Kunde steht dafür ein, dass die von ihm bei der Registrierung gegenüber betterHR gemachten Angaben wahr und vollständig sind. Für den Fall, dass der Kunde unwahre oder unvollständige Angaben macht, kann betterHR den mit dem Kunden geschlossenen Vertrag fristlos kündigen und das erstellte Kundenkonto nach angemessener Zeit löschen.
					</p>
					<p className="mt-2">
          2.6 betterHR behält sich vor, die Registrierung eines Kunden ohne Angabe von Gründen abzulehnen oder den Kunden nachträglich von der vollständigen Nutzung der betterHR-Dienste auszuschließen.
					</p>
					<p className="mt-2">
          2.7 Ein durch den Kunden erstelltes Kundenkonto ist nicht auf Dritte übertragbar.
					</p>
				</div>
				<hr/>
				<div className="my-row mt-4">
					<h4>
          3. Dienste und Inhalte
					</h4>
				</div>
				<div className="my-row">
					<p>
          3.1 Nach erfolgreicher Freischaltung des Kundenkontos erhält der Kunde neben den frei zugänglichen Diensten auf <b>www.betterHR.de</b> Zugang zu erweiterten kostenfreien Leistungen von betterHR als auch zu verschiedenen kostenpflichtigen On-Demand-Diensten („betterHR On-Demand“). Der Inhalt und Umfang der betterHR-Dienste und von betterHR On-Demand richtet sich nach den jeweiligen vertraglichen Vereinbarungen, im Übrigen nach den jeweils auf <b>www.betterhr.de</b> verfügbaren Angeboten.
					</p>
					<p className="mt-2">
          3.2 Das Angebot von betterHR enthält teilweise Dienste Dritter, zu welchen betterHR lediglich den Zugang vermittelt. Für die Inanspruchnahme derartiger Dienste – die jeweils als Dienste Dritter gekennzeichnet sind – können von diesen AGB abweichende zusätzliche Regelungen gelten, auf die der Dritte den Kunden jeweils hinweisen wird. betterHR führt bei Diensten Dritter keine Prüfung auf Vollständigkeit, Richtigkeit und Rechtmäßigkeit durch und übernimmt daher keine Verantwortung oder Gewährleistung für die Vollständigkeit, Richtigkeit, Rechtmäßigkeit und Aktualität der Drittinhalte als auch für die Qualität oder Eignung solcher Dienste Dritter für einen bestimmten Zweck.
					</p>
					<p className="mt-2">
          3.3 betterHR behält sich vor, die betterHR-Dienste zu ändern, zu ergänzen oder teilweise einzustellen.
					</p>
					<p className="mt-2">
          3.4 betterHR gewährt für sämtliche betterHR-Dienste in ihrem Verantwortungsbereich eine Verfügbarkeit von 99 % im Monatsmittel. betterHR führt an ihren Systemen zur Sicherheit des Netzbetriebes, zur Aufrechterhaltung der Netzintegrität, der Interoperabilität der Dienste und des Datenschutzes regelmäßig Wartungsarbeiten durch. Zu diesem Zwecke können die betterHR-Dienste unter Berücksichtigung der Belange des Kunden vorübergehend eingestellt oder beschränkt werden, soweit wichtige Gründe dies rechtfertigen. Sollten längere vorübergehende Leistungseinstellungen oder -beschränkungen erforderlich sein, wird betterHR den Kunden über Art, Ausmaß und Dauer der Beeinträchtigung unterrichten, soweit dies den Umständen nach objektiv möglich ist und die Unterrichtung die Beseitigung bereits eingetretener Unterbrechungen nicht verzögern würde. Im Übrigen besteht Anspruch nur auf die Nutzung der betterHR-Dienste im Rahmen der technischen und betrieblichen Möglichkeiten von betterHR. betterHR bemüht sich um eine möglichst unterbrechungsfreie Nutzbarkeit der betterHR-Dienste. Zeitweilige Beschränkungen oder Unterbrechungen der betterHR-Dienste durch technische Störungen (z.B. Unterbrechung der Stromversorgung, Hardware- und Softwarefehler, technische Probleme der Datenleitungen) hat betterHR grundsätzlich nicht zu vertreten.
					</p>
				</div>
				<hr/>
				<div className="my-row mt-4">
					<h4>
          4. Vergütung
					</h4>
				</div>
				<div className="my-row">
					<p>
          4.1 Die Nutzung der betterHR-Dienste ist für den Kunden kostenlos.
					</p>
					<p className="mt-2">
          4.2 Soweit Kunden bei betterHR kostenpflichtige betterHR On-Demand Angebote hinzubucht, unterliegen diese den auf der <b>www.betterHR.de</b> hinterlegten, bei Vertragsschluss gültigen Preisen.
					</p>
					<p>
          4.3 Kunden können zwischen den von betterHR angegebenen unterschiedlichen Zahlungsmethoden wählen. betterHR behält sich vor, weitere Zahlungsmethoden anzubieten bzw. Zahlungsmethoden nicht mehr zur Verfügung zu stellen. Die Zahlungsweise wird verbindlich beim Bestellvorgang des betterHR On-Demand-Angebotes durch den Kunden ausgewählt. Sofern ein Kunde eine Online-Zahlungsmethode wählt, wird die Zahlung ggf. durch einen mit betterHR kooperierenden externen Zahlungsdiensteanbieter abgewickelt. Bei Zahlung nach Rechnungsstellung behält betterHR sich eine Bonitätsprüfung vor. Sämtliche Vergütungen werden mit Zugang der Rechnung fällig und sind ohne Abzug zahlbar.
					</p>
					<p className="mt-2">
          4.4 Sämtliche Nutzungsvergütungen verstehen sich in Euro einschließlich der gesetzlichen MwSt. Für Kunden, die ihren (Wohn)Sitz außerhalb Deutschlands haben, insbesondere in EU-Staaten oder Nicht-EU-Staaten, können für die Frage der Mehrwertsteuerbarkeit der betterHR-Dienste und die Höhe der jeweiligen Mehrwertsteuer abweichende Regelungen gelten.
					</p>
					<p className="mt-2">
          4.5 Kunden von betterHR erhalten eine elektronische Rechnung per E-Mail. Verlangt der Kunde die postalische Zusendung einer Rechnung, ist betterHR berechtigt, hierfür ein Entgelt von 1,50 € je Rechnung zu verlangen. Bei postalischen Zusendungen außerhalb Deutschlands ist betterHR berechtigt, ein Entgelt von 3,50 € je Rechnung zu verlangen.
					</p>
					<p className="mt-2">
          4.6 Sämtliche vertragsgegenständlichen Vergütungen sind, soweit nicht abweichend vereinbart, im nach Leistungserbringung zur Zahlung fällig. betterHR ist berechtigt, den Zugang zu den betterHR-Diensten und betterHR On-Demand Angeboten zu sperren, sofern der Kunde mit einer Zahlung in Verzug gerät bzw. die übermittelten Abrechnungsdaten-/- unterlagen nicht zu einer pünktlichen und vollständigen Zahlung fälliger Forderungen der betterHR führen.
					</p>
					<p className="mt-2">
          4.7 Die Aufrechnung ist dem Kunden nur mit unbestrittenen und rechtskräftig festgestellten Gegenforderungen erlaubt. Ein Zurückbehaltungsrecht kann der Kunde nur geltend machen, wenn es auf demselben Vertragsverhältnis beruht.
					</p>
				</div>
				<hr/>
				<div className="my-row mt-4">
					<h4>
          5. Haftungsbeschränkung
					</h4>
				</div>
				<div className="my-row">
					<p>
          5.1 Die von betterHR bereit gestellten Informationen und Aussagen im Rahmen der betterHR-Dienste dienen lediglich Informationszwecken. betterHR bemüht sich, sämtliche im Rahmen der betterHR-Dienste bereit gestellten Informationen mit Sorgfalt auszuwählen und bei Bedarf zu aktualisieren oder zu ergänzen. betterHR haftet insbesondere nicht für Vollständigkeit, Richtigkeit, Aktualität, Verlässlichkeit oder Eignung der bereitgestellten Inhalte für bestimmte Zwecke.
					</p>
					<p className="mt-2">
          5.2 Die betterHR-Dienste und betterHR On-Demand Angebote stellen keine Rechtsberatung dar und können auch keine individuelle rechtliche Beratung ersetzen, welche die Besonderheiten des Einzelfalles berücksichtigt. betterHR übernimmt daher keinerlei Haftung für die rechtliche Vollständigkeit, Richtigkeit, Aktualität, Verlässlichkeit von Informationen oder Aussagen von betterHR oder deren Eignung für einen bestimmten Zweck.
					</p>
					<p>
          5.3 Soweit betterHR die jeweilige haftungsauslösende Leistung entgeltfrei erbringt, haftet betterHR dem Kunden im Übrigen nur für Vorsatz und grobe Fahrlässigkeit.
					</p>
					<p className="mt-2">
          5.4 Die Haftung von betterHR bei entgeltlichen Leistungen, gleich aus welchem Rechtsgrund, sei es aus vertraglicher Pflichtverletzung oder unerlaubter Handlung bestimmt sich abschließend nach den folgenden Regelungen Ziffer 5.5-5.7.
					</p>
					<p className="mt-2">
          5.5 betterHR haftet für Vorsatz und grobe Fahrlässigkeit sowie im Fall von Personenschäden uneingeschränkt, für leichte Fahrlässigkeit jedoch nur bei der Verletzung vertragswesentlicher Pflichten, bei von betterHR zu vertretendem Verzug und Unmöglichkeit. Die Haftung bei Verletzung einer solchen vertragswesentlichen Pflicht ist auf den vertragstypischen Schaden begrenzt, mit dessen Entstehen betterHR bei Vertragsabschluss auf Grund der zu diesem Zeitpunkt bekannten Umstände rechnen musste. „Vertragswesentliche Pflichten“ im vorbezeichneten Sinne sind solche Pflichten, welche die ordnungsgemäße Durchführung des Vertrages und die Erreichung seines Zwecks erst ermöglichen und auf deren Einhaltung der Kunde vertrauen darf.
					</p>
					<p className="mt-2">
          5.6 Die Haftung der betterHR nach den Produkthaftungsgesetz bleibt unberührt.
					</p>
					<p className="mt-2">
          5.7 Vorstehende Haftungsausschlüsse bzw. -beschränkungen gelten auch im Hinblick auf die Haftung der Angestellten, Arbeitnehmer, Mitarbeiter, Vertreter und Erfüllungsgehilfen der betterHR.
					</p>
				</div>
				<hr/>
				<div className="my-row mt-4">
					<h4>
          6. Sperrung, Beendigung
					</h4>
				</div>
				<div className="my-row">
					<p>
          6.1 betterHR ist berechtigt, den Zugang eines Kunden zu den betterHR-Diensten vorübergehend oder dauerhaft zu sperren, wenn konkrete Anhaltspunkte vorliegen, dass der Kunde gegen diese AGB und / oder geltenden Recht verstößt bzw. verstoßen hat oder betterHR ein sonstiges berechtigtes Interesse an einer Sperrung hat. Bei der Entscheidung über die Sperrung wird betterHR die Interessen des Kunden angemessen berücksichtigen. Im Falle einer dauerhaften Sperrung ist es Kunden nicht erlaubt, sich erneut auf den zur Nutzunder betterHR-Dienste anzumelden.
					</p>
					<p className="mt-2">
          6.2 Sofern für das betterHR On-Demand Angebot nicht ausdrücklich etwas anderes bestimmt ist, wird der Vertrag zur Nutzung der betterHR-Dienste und betterHR On-Demand Angebote auf unbestimmte Zeit abgeschlossen. Er beginnt mit der Freischaltung des Kundenkontos.
					</p>
					<p className="mt-2">
          6.3 Der Vertrag kann von beiden Parteien jederzeit ordentlich mit sofortiger Wirkung gekündigt werden.
					</p>
					<p className="mt-2">
          6.4 Das Recht der Parteien zur jederzeitigen Kündigung aus wichtigem Grund bleibt von den vorstehenden Regelungen unberührt.
					</p>
					<p className="mt-2">
          6.5 Jedwede Kündigung bedarf zur Wirksamkeit der Schriftform. Die Schriftform wird durch einen entsprechende Mitteilung per E-Mail an <b>support@betterhr.de</b> gewahrt.
					</p>
				</div>
				<hr/>
				<div className="my-row mt-4">
					<h4>
          7. Nutzungsrechte
					</h4>
				</div>
				<div className="my-row">
					<p>
          7.1 Die im Rahmen der betterHR-Dienste und betterHR On-Demand Angebote verfügbaren Inhalte sind durch das Urheberrecht oder durch sonstige Schutzrechte geschützt und stehen jeweils im Eigentum von betterHR oder sonstiger Dritter, welche die jeweiligen Inhalte zur Verfügung gestellt haben. Die Zusammenstellung der Inhalte als Solche ist ggf. geschützt als Datenbank oder Datenbankwerk iSd. §§ 4 Abs. 2, 87a Abs. 1 UrhG.
					</p>
					<p className="mt-2">
          7.2 Kunden dürfen urheberrechtlich geschützte Inhalte von betterHR oder Dritter, welche die jeweiligen Inhalte betterHR zur Verfügung gestellt haben ausschließlich für eigene Zwecke gemäß dieser AGB sowie im auf dem Portal vorgegebenen Rahmen nutzen. Jedwede gewerbliche Nutzung von urheberrechtlich geschützten Inhalten ist den Kunden untersagt. Kunden sind insbesondere nicht berechtigt, urheberrechtlich geschützte Inhalte zu vervielfältigen und Dritten unentgeltlich oder entgeltlich zur Verfügung zu stellen bzw. Dritten einen gesonderten Zugriff bzw. Zugang auf die betterHR-Dienste, die betterHR On-Demand Angebote zu ermöglichen.
					</p>
					<p className="mt-2">
          7.3 Kunden ist es insbesondere auch untersagt, Copyrightvermerke, Kennzeichen/Markenzeichen und/oder Eigentumsangaben von betterHR oder auf <b>www.betterHR.de</b> eingebundenen Dritten zu verändern.
					</p>
				</div>
				<hr/>
				<div className="my-row mt-4">
					<h4>
          8. Datenschutz
					</h4>
				</div>
				<div className="my-row">
					<p>
          Personenbezogene Daten des Kunden werden nur erhoben, verarbeitet oder genutzt, sofern der Kunde eingewilligt hat oder die Datenschutzgrundverordnung (DS-GVO), das Bundesdatenschutzgesetz (BDSG), das Telemediengesetz (TMG) oder eine andere Rechtsvorschrift es anordnet oder erlaubt. Im Übrigen gilt die Datenschutzerklärung von betterHR.
					</p>
				</div>
				<hr/>
				<div className="my-row mt-4">
					<h4>
          9. Schlussbestimmungen
					</h4>
				</div>
				<div className="my-row">
					<p>
          9.1 betterHR kommuniziert mit dem Kunden in der Regel per E-Mail. Der Kunde stellt sicher, dass die im Rahmen der Registrierung angegebene E-Mail-Adresse regelmäßig auf Nachrichten von betterHR überprüft wird.
					</p>
					<p className="mt-2">
          9.2 Sollten einzelne Bestimmungen dieser AGB unwirksam sein oder werden und / oder den gesetzlichen Regelungen widersprechen, so wird hierdurch die Wirksamkeit der Nutzungsbedingungen im Übrigen nicht berührt. Die unwirksame Bestimmung wird von den Vertragsparteien einvernehmlich durch eine solche Bestimmung ersetzt, welche dem wirtschaftlichen Sinn und Zweck der unwirksamen Bestimmung in rechtswirksamer Weise am Nächsten kommt. Die vorstehende Regelung gilt entsprechend bei Regelungslücken.
					</p>
					<p className="mt-2">
          9.3 Für diese AGB und alle auf Basis dieser AGB abgeschlossenen Verträge gilt das Recht der Bundesrepublik Deutschland. Die Anwendung des UN-Kaufrecht über den Kauf beweglicher Sachen sowie der Kollisionsregeln des deutschen internationalen Privatrechts ist ausgeschlossen.
					</p>
					<p className="mt-2">
          9.4 Gerichtsstand für alle Streitigkeiten aus diesen AGB ist, soweit dies gesetzlich zulässig ist, der Sitz von betterHR.
					</p>
				</div>
				<hr/>
				<div className="my-row my-5">
					<p>
          Stand 04/06/2019
						<br/>
          Version 1.2
					</p>
				</div>
			</div>
		</Layout>
	)
}
 
export default Agb